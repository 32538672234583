import React, { useEffect, useState } from "react";
import { mutate } from "swr";

import { stitchAPI } from "../../data/fetcher";
import { StateSlot } from "../../data/state_slots/models";
import { Step } from "../../data/steps/models";
import { createStateSlot } from "../../data/state_slots/controllers";

import { StateSlot as StateSlotComp } from "../StateSlot";
import { DialogCreateSlot } from "../DialogCreateSlot";

interface UIInputStateProps {
    input: any;
    onChange: (v: any) => void;
    step: Step;
    stateSlots: StateSlot[];
    setDialogContent: (v: JSX.Element | null) => void;
}

export function UIInputState(props: UIInputStateProps): JSX.Element {
    const { input, onChange, step, stateSlots, setDialogContent } = props;

    const [filteredStateSlots, setFilteredStateSlots] = useState<StateSlot[]>(
        []
    );
    // FIXME: This is hacky
    const isFlowInputPicker =
        step.type === "flow_alias" && input.key == "state_id";

    useEffect(() => {
        if (isFlowInputPicker) {
            stitchAPI<{ state_slots: StateSlot[] }>(
                `/state_slots/?flow_id=${step.run_flow_id!}`
            ).then((response) => {
                setFilteredStateSlots(
                    response.state_slots.filter((s) => s.step_id === null)
                );
            });
        } else {
            setFilteredStateSlots(stateSlots);
        }
    }, [step]);

    async function createStateSlotHandler(
        name: string,
        type: string
    ): Promise<StateSlot> {
        const newStateSlot = new StateSlot(step.flow_id, step.id, name, type);
        const createdStateSlot = await createStateSlot(newStateSlot);
        // Tell all other components to re-fetch the state slots
        await mutate(`/state_slots/?flow_id=${step.flow_id}`);

        return createdStateSlot;
    }

    const slotOptions = filteredStateSlots.filter(
        (s) =>
            input.state_type === "anything" ||
            s.type === input.state_type ||
            s.type === "anything"
    );
    const selectedSlot = filteredStateSlots.find((s) => s.id === input.value);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div
            className={`UIInput__select ${
                selectedSlot && "UIInput__select--filled"
            }`}
            tabIndex={0}
            onClick={() => setIsOpen(true)}
            onBlur={() => setIsOpen(false)}
            onFocus={() => setIsOpen(true)}
        >
            <div className="UIInput__select__value">
                {selectedSlot ? (
                    <StateSlotComp {...selectedSlot} />
                ) : (
                    <div className="UIInput__select__value--placeholder">
                        {input.placeholder}
                    </div>
                )}
            </div>
            {selectedSlot && (
                <button
                    className="UIInput__deleter UIInput__select__clear"
                    onClick={(e) => {
                        onChange(null);
                        e.stopPropagation();
                    }}
                />
            )}
            {isOpen && (
                /* TODO: Make this a <Menu> component */
                <div className="Menu UIInput__select__menu">
                    <div className="Menu__section">
                        {!isFlowInputPicker && (
                            <div
                                className="Menu__section__item"
                                onClick={(e) => {
                                    setDialogContent(
                                        <DialogCreateSlot
                                            handleCreate={async (
                                                name,
                                                type
                                            ) => {
                                                const stateSlot =
                                                    await createStateSlotHandler(
                                                        name,
                                                        type
                                                    );
                                                onChange(stateSlot.id);
                                                setDialogContent(null);
                                            }}
                                            clearContent={() => {
                                                setDialogContent(null);
                                            }}
                                            lockType={
                                                input.state_type !== "anything"
                                                    ? input.state_type
                                                    : null
                                            }
                                        />
                                    );
                                }}
                            >
                                New Slot
                            </div>
                        )}
                    </div>
                    <div className="Menu__section">
                        {slotOptions.map((slot, i) => (
                            <div
                                className="Menu__section__item"
                                key={`${slot}_${i}`}
                                onClick={(e) => {
                                    onChange(slot.id);
                                    setIsOpen(false);
                                    e.stopPropagation();
                                }}
                            >
                                <StateSlotComp {...slot} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}
