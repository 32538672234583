export interface NextStep {
    id: string;
    label: string;
    step_id?: string;
}

export class Step {
    id: string;
    org_id: string;
    date_created: string;
    flow_id: string;
    type: string;
    position: number[];
    inputs: object[];
    next_steps: NextStep[];
    run_flow_id?: string;

    constructor(
        flow_id: string,
        type: string,
        position: number[] = [0, 0],
        id: string = "temp-step-id",
        org_id: string = "temp-org-id",
        next_steps: NextStep[] = [],
        inputs: object[] = [],
        date_created: string = new Date().toISOString()
    ) {
        this.id = id;
        this.org_id = org_id;
        this.date_created = date_created;
        this.flow_id = flow_id;
        this.type = type;
        this.position = position;
        this.inputs = inputs;
        this.next_steps = next_steps;
    }
}

export interface StepDef {
    color_hex: string;
    description: string;
    icon_url: string;
    name: string;
    type: string;
}
