import React from "react";
import { Handle } from "react-flow-renderer";
import { StepRunRow } from "../../../../data/flows/models";
import { Step, StepDef } from "../../../../data/steps/models";

import "./styles.scss";

export const FlowPageEditorStep = ({ data }) => {
    const {
        step,
        stepDef,
        stepRunResult,
        isFirst,
    }: {
        step: Step;
        stepDef: StepDef | null;
        stepRunResult: StepRunRow | null;
        isFirst: boolean;
    } = data;

    const runResultClass = stepRunResult
        ? `FlowPageEditorStep--ran_${stepRunResult[0].status}`
        : "";
    const firstStepClass = isFirst ? "FlowPageEditorStep--first" : "";

    return (
        <div
            className={`FlowPageEditorStep ${runResultClass} ${firstStepClass}`}
            id={step.id}
        >
            <div className={"FlowPageEditorStep__header"}>
                <Handle
                    id="0"
                    type="target"
                    // @ts-ignore
                    position="left"
                    className={
                        "FlowPageEditorStep__connection FlowPageEditorStep__header__target"
                    }
                />
                <div
                    className={`FlowPageEditorStep__header__icon ${
                        stepDef && stepDef.type.includes("flow_alias")
                            ? "FlowPageEditorStep__header__icon--flow_alias"
                            : ""
                    }`}
                    style={{
                        backgroundColor: stepDef ? stepDef.color_hex : "grey",
                    }}
                />
                <div className={"FlowPageEditorStep__header__label"}>
                    {stepDef ? stepDef.name : "Unknown Step Type"}
                </div>
            </div>
            {step.next_steps.map((slot, i) => (
                <div className={"FlowPageEditorStep__output"} key={i}>
                    <div className="FlowPageEditorStep__output__label">
                        {slot.label}
                    </div>
                    <Handle
                        id={slot.id}
                        type="source"
                        // @ts-ignore
                        position="right"
                        data-label={slot.label}
                        className={
                            "FlowPageEditorStep__connection FlowPageEditorStep__output__source"
                        }
                    />
                </div>
            ))}
        </div>
    );
};
