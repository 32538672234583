import React, { useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import { API_FQD } from "../../../constants";
import { stitchAPI } from "../../../data/fetcher";
import { updateFlow } from "../../../data/flows/controllers";
import { Flow } from "../../../data/flows/models";
import { StateSlot } from "../../../data/state_slots/models";
import { StateSlot as StateSlotComp } from "../../StateSlot";
import { DialogCreateSlot } from "../../DialogCreateSlot";
import { UIToggle } from "../../UIToggle";

import "./styles.scss";
import { UIInputSingleText } from "../../UIInputSingleText";

interface FlowSettingsProps {
    flow: Flow;
    hidden: boolean;
    closeHandler: () => void;
    setDialogContent: (content: JSX.Element | null) => void;
}

export function UIInputCopiable(props: {
    url: string;
    domName: string;
    label: string;
}) {
    const { url, domName, label } = props;
    const [urlWasCopied, setUrlWasCopied] = useState(false);

    useEffect(() => {
        if (urlWasCopied) {
            setTimeout(() => {
                setUrlWasCopied(false);
            }, 2000);
        }
    }, [urlWasCopied]);

    return (
        <div className="UIInputCollection UIInputCollection--row">
            <div className="UIInput">
                <label htmlFor={domName}>{label}</label>
                <input
                    className="input"
                    id={domName}
                    type="text"
                    value={url}
                    readOnly={true}
                />
            </div>
            <button
                className="UIButton UIButton--light"
                style={{ marginTop: "auto" }}
                onClick={() => {
                    navigator.clipboard.writeText(url);
                    setUrlWasCopied(true);
                }}
            >
                {urlWasCopied ? "Copied" : "Copy"}
            </button>
        </div>
    );
}

export function FlowPageSettings(props: FlowSettingsProps): JSX.Element {
    const { hidden, closeHandler, flow, setDialogContent } = props;
    const [flowName, setFlowName] = useState<string | null>(flow.name);
    const [flowIsStep, setFlowIsStep] = useState<boolean>(flow.is_step);
    const [flowIsShared, setFlowIsShared] = useState<boolean>(flow.is_shared);
    const [flowInputSlots, setFlowInputSlots] = useState<StateSlot[]>([]);

    const { data: flowStateSlots, mutate: mutateStateSlots } = useSWR<
        StateSlot[],
        Error
    >(`/state_slots/?flow_id=${flow.id}`, async (route) => {
        const data: { state_slots: StateSlot[] } = await stitchAPI(route);
        return data.state_slots;
    });

    useEffect(() => {
        if (flowStateSlots) {
            setFlowInputSlots(
                flowStateSlots.filter((slot) => slot.step_id === null)
            );
        }
    }, [flowStateSlots]);

    return (
        <div
            className={`Modal Settings ${hidden && "Modal--hidden"}`}
            onClick={() => {
                closeHandler();
            }}
        >
            <div className="Modal__inner" onClick={(e) => e.stopPropagation()}>
                <h2>Settings</h2>
                <div className="Section">
                    <div className="Section__title">General</div>
                    <div className="UIInputCollection UIInputCollection--row">
                        <div className="UIInput">
                            <label htmlFor="flowName">Name</label>
                            <input
                                className="input"
                                placeholder="Untitled Flow"
                                id="flowName"
                                type="text"
                                value={flowName || undefined}
                                onBlur={async () => {
                                    await updateFlow(flow.id, {
                                        name: flowName,
                                    });
                                    mutate("/steps/defs/");
                                }}
                                onChange={(e) => {
                                    setFlowName(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <br></br>
                    <UIInputCopiable
                        url={`${API_FQD}/flows/${flow.id}/run/`}
                        label="URL"
                        domName="flowURL"
                    />
                    <div className="Section__callout">
                        Use this URL to run your Flow from anywhere. Pass in any
                        Input Slots either as json data or url arguments.
                    </div>
                </div>
                <div className="Section">
                    <div className="Section__title">Sharing</div>
                    <div className="UIInputCollection UIInputCollection--row">
                        <div className="UIInput UIInput--inline">
                            <UIToggle
                                label="Make Step"
                                value={flowIsStep}
                                onChange={async (checked) => {
                                    setFlowIsStep(checked);
                                    await updateFlow(flow.id, {
                                        is_step: checked,
                                    });
                                    mutate("/steps/defs/");
                                }}
                            />
                            <div className="Section__callout">
                                When on, you will be able to run this Flow as a
                                Step in other Flows
                            </div>
                        </div>
                        <div className="UIInput UIInput--inline">
                            <UIToggle
                                label="Share"
                                value={flowIsShared}
                                onChange={async (checked) => {
                                    setFlowIsShared(checked);
                                    await updateFlow(flow.id, {
                                        is_shared: checked,
                                    });
                                }}
                            />
                            <div className="Section__callout">
                                When on, this Flow can be copied with the URL
                                below
                            </div>
                        </div>
                    </div>
                    {flowIsShared && (
                        <UIInputCopiable
                            url={`${window.location.origin}/share/${flow.share_id}/`}
                            label="Share URL"
                            domName="shareURL"
                        />
                    )}
                </div>
                <div className="Section FlowInputSlots">
                    <div className="Section__title">Input Slots</div>
                    <div className="UIInputCollection FlowInputSlots__slots">
                        {flowInputSlots.map((slot) => (
                            <div
                                className="FlowInputSlots__slots__row"
                                key={slot.id}
                            >
                                <StateSlotComp {...slot} />
                                <button
                                    className="UIInput__deleter"
                                    onClick={async (_) => {
                                        await stitchAPI(
                                            `/state_slots/${slot.id}/`,
                                            {
                                                method: "DELETE",
                                            }
                                        );
                                        mutateStateSlots();
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                    <button
                        className="UIButton UIButton--light Adder"
                        onClick={() => {
                            setDialogContent(
                                <DialogCreateSlot
                                    handleCreate={async (name, type) => {
                                        await stitchAPI("/state_slots/", {
                                            method: "POST",
                                            body: JSON.stringify({
                                                flow_id: flow.id,
                                                name: name,
                                                type: type,
                                            }),
                                        });
                                        mutateStateSlots();
                                        setDialogContent(null);
                                    }}
                                    lockType={null}
                                    clearContent={() => {
                                        setDialogContent(null);
                                    }}
                                />
                            );
                        }}
                    >
                        Add Input Slot
                    </button>
                    <div className="Section__callout">
                        Input Slots are how you can define state before your
                        Flow is run. If you publish your Flow as a Step, these
                        can be used by the user. If you are just calling your
                        Flow from a URL, these will be used to validate the
                        request's URL arguments or JSON body data.
                    </div>
                </div>
            </div>
        </div>
    );
}
