import React, { useEffect, useState } from "react";

import { UIInputCollection } from "../UIInputCollection";

export const UIInputList = (props) => {
    const { input, onChange, stateSlots, step, setDialogContent } = props;
    const values = input.value;

    const inputChange = (rowIndex, newValue) => {
        const newInputValues = [...values];
        const newRow = newInputValues[rowIndex];
        const inputKey = newValue.key;

        newRow.forEach((element, i) => {
            if (element.key === inputKey) {
                newRow[i] = newValue;
            }
        });

        onChange(newInputValues);
    };

    const addRow = () => {
        const newValues = [...values];
        newValues.push(input.row_schema);
        onChange(newValues);
    };

    const deleteRow = (index) => {
        const newValues = [...values];
        newValues.splice(index, 1);
        onChange(newValues);
    };

    return (
        <div className={`UIInput__list`}>
            {values.map((row, i) => (
                <div className="UIInput__list__row" key={i}>
                    <UIInputCollection
                        showLabels={false}
                        stateSlots={stateSlots}
                        setDialogContent={setDialogContent}
                        step={step}
                        inputs={row}
                        showAsRow={input.align_as_row}
                        onChange={(newValue) => inputChange(i, newValue)}
                    />
                    <button
                        className="UIInput__deleter"
                        onClick={() => deleteRow(i)}
                    />
                </div>
            ))}
            <button className="UIButton UIButton--light" onClick={addRow}>
                {input.add_row_label}
            </button>
        </div>
    );
};
