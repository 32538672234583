import React from "react";

import "./styles.scss";

interface UIToggleProps {
    label: string;
    value: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
}

export function UIToggle(props: UIToggleProps): JSX.Element {
    const { label, value, onChange, disabled } = props;

    return (
        <div className="UIToggle">
            <label className="UIToggle__label" htmlFor={label}>
                {label}
            </label>
            <div
                className={`UIToggle__input ${
                    value == true && "UIToggle__input--checked"
                }`}
            >
                <input
                    id={label}
                    type="checkbox"
                    checked={value}
                    disabled={disabled}
                    onChange={(e) => {
                        onChange(e.target.checked);
                    }}
                />
            </div>
        </div>
    );
}
