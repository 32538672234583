import React, { useState } from "react";
import { UIInputSingleText } from "../UIInputSingleText";

export function DialogCreateSlot(props): JSX.Element {
    const { clearContent, lockType, handleCreate } = props;
    const [slotName, setSlotName] = useState("");
    const [slotType, setSlotType] = useState(lockType);

    return (
        <div className="Dialog__content UIInputCollection">
            <div style={{ alignSelf: "center", marginBottom: "1.5rem" }}>
                Create a Slot
            </div>
            <div className="UIInput">
                <UIInputSingleText
                    autoFocus={true}
                    input={{
                        key: 0,
                        value: slotName,
                        placeholder: "Slot Name",
                    }}
                    onChange={(v) => {
                        setSlotName(v);
                    }}
                />
            </div>
            <div className="UIInput">
                <UIInputSingleText
                    input={{
                        key: 0,
                        value: slotType,
                        placeholder: "Slot Type",
                        options: [
                            "text",
                            "number",
                            "anything",
                            "list",
                            "object",
                            "bool",
                        ],
                    }}
                    disabled={lockType !== null}
                    onChange={(v) => {
                        setSlotType(v);
                    }}
                />
            </div>
            <button
                className="UIButton"
                disabled={slotName === "" || !slotType}
                onClick={() => {
                    handleCreate(slotName, slotType);
                }}
            >
                Create
            </button>
            <button
                className="UIButton UIButton--light"
                onClick={() => clearContent()}
            >
                Cancel
            </button>
        </div>
    );
}
