export class StateSlot {
    id: string;
    org_id: string;
    date_created: string;
    flow_id: string;
    step_id: string;
    type: string;
    scope: string;
    name: string;

    constructor(
        flow_id: string,
        step_id: string,
        name: string,
        type: string,
        id: string = "temp-id",
        org_id: string = "temp-org-id",
        date_created: string = new Date().toISOString(),
        scope: string = "local"
    ) {
        this.id = id;
        this.org_id = org_id;
        this.flow_id = flow_id;
        this.step_id = step_id;
        this.date_created = date_created;
        this.type = type;
        this.scope = scope;
        this.name = name;
    }
}
