import React from "react";

import "./styles.scss";

const iconMap = {
    anything: "*",
    text: "t",
    number: "#",
    bool: "✓",
    object: "{}",
    list: "[]",
};

const colorMap = {
    local: ["#6f1761", "#fad8f3"],
    unknown: ["#717171", "#f9f7f9"],
};

interface StateSlotProps {
    id: string;
    name: string;
    type: string;
    scope: string;
}

export function StateSlot(props: StateSlotProps): JSX.Element {
    const { id, name, type, scope } = props;

    return (
        <span
            id={id}
            style={{
                color: colorMap[scope][0],
                backgroundColor: colorMap[scope][1],
            }}
            className="StateSlot"
        >
            <span style={{ paddingRight: "0.33rem", opacity: "0.5" }}>
                {iconMap[type]}
            </span>
            {name}
        </span>
    );
}
