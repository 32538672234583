import React, { useState, useEffect, useContext } from "react";
import { Navigate, useParams } from "react-router-dom";
import useSWR from "swr";

import { FlowPageEditor } from "./FlowPageEditor";
import { FlowPageStepDrawer } from "./FlowPageStepDrawer";
import { FlowPageInspector } from "./FlowPageInspector";
import { FlowPageControlBar } from "./FlowPageControlBar";
import {
    deleteStep,
    updateStep,
    createStep,
} from "../../data/steps/controllers";
import { testFlow, updateFlow } from "../../data/flows/controllers";
import { FlowPageSettings } from "./FlowPageSettings";
import { Dialog } from "../Dialog";
import { Flow, FlowRunResult } from "../../data/flows/models";
import { stitchAPI } from "../../data/fetcher";
import { Step, StepDef } from "../../data/steps/models";
import { StateSlot } from "../../data/state_slots/models";
import FullScreen from "../FullScreen";
import { UserContext } from "../../data/users/context";

export default function FlowPage() {
    const { user } = useContext(UserContext);
    const [drawerHidden, setDrawerHidden] = useState(false);
    const [settingsHidden, setSettingsHidden] = useState(true);
    const [dialogContent, setDialogContent] = useState<JSX.Element | null>(
        null
    );
    const [selectedStepId, setSeletedStepId] = useState<string | null>(null);
    const [flowRunResult, setflowRunResult] = useState<FlowRunResult | null>(
        null
    );
    const [flowIsRunning, setFlowIsRunning] = useState(false);
    const { flowId } = useParams();

    // Get the possible step definitions
    const { data: stepDefs, error: stepDefsError } = useSWR<StepDef[], Error>(
        "/steps/defs/",
        async (route) => {
            const data: { steps: StepDef[] } = await stitchAPI(route);
            return data.steps;
        }
    );

    // When a flowId is provided, fetch the flow
    const {
        data: flow,
        mutate: mutateFlow,
        error: flowError,
    } = useSWR<Flow, Error>(flowId ? `/flows/${flowId}/` : null, stitchAPI);

    console.log(flow);

    // When a flow is loaded, get the steps
    const { data: flowSteps, mutate: mutateFlowSteps } = useSWR<Step[], Error>(
        flow ? `/flows/${flow.id}/steps/` : null,
        async (route) => {
            const data: { steps: Step[] } = await stitchAPI(route);
            return data.steps;
        }
    );

    // When a flow is loaded, get the slots
    const { data: flowStateSlots, mutate: mutateStateSlots } = useSWR<
        StateSlot[],
        Error
    >(flow ? `/state_slots/?flow_id=${flowId}` : null, async (route) => {
        const data: { state_slots: StateSlot[] } = await stitchAPI(route);
        return data.state_slots;
    });

    useEffect(() => {
        document.title = flow && flow.name ? `Stitch - ${flow.name}` : "Stitch";
    }, [flow]);

    if (!user) {
        return <Navigate to={`/sign-in/`} />;
    }

    if (!flowStateSlots || !flowSteps || !stepDefs) {
        return <FullScreen title="Loading" />;
    }

    if (!flowId) {
        // No URL flow ID, so user should make a new flow
        return <Navigate to="/" />;
    }

    if (flowError) {
        return <FullScreen title="Error" description={flowError.message} />;
    }

    if (stepDefsError) {
        return <FullScreen title="Error" description={stepDefsError.message} />;
    }

    if (!stepDefs || !flow || !flowSteps || !flowStateSlots) {
        return <FullScreen title="Loading" />;
    }

    function updateStepHandler(stepId: string, updates: Partial<Step>): void {
        mutateFlowSteps(
            async () => {
                const updatedStep = await updateStep(stepId, updates);
                const newFlowSteps = [...flowSteps!];
                const stepIndex = newFlowSteps.findIndex(
                    (s) => s.id === stepId
                );
                newFlowSteps[stepIndex] = updatedStep;
                return newFlowSteps;
            },
            {
                optimisticData: () => {
                    // TODO: Refactor
                    const newFlowSteps = [...flowSteps!];
                    const step = newFlowSteps.find(
                        (step) => step.id === stepId
                    )!;
                    const stepIndex = newFlowSteps.findIndex(
                        (s) => s.id === stepId
                    );
                    newFlowSteps[stepIndex] = { ...step, ...updates };
                    return newFlowSteps;
                },
                revalidate: false,
                rollbackOnError: true,
                populateCache: true,
            }
        );
    }

    const updateFlowHandler = (updates: Partial<Flow>) => {
        mutateFlow(updateFlow(flow.id, updates), {
            optimisticData: { ...flow, ...updates },
            revalidate: true,
            rollbackOnError: true,
            populateCache: true,
        });
    };

    function createStepHandler(dropData: string, canvasPosition: any): void {
        const newStep = new Step(flow!.id, dropData, [
            canvasPosition.x,
            canvasPosition.y,
        ]);

        mutateFlowSteps(
            async () => {
                const createdStep = await createStep(newStep);
                if (flowSteps!.length === 0) {
                    // If this is the first step, set it as the start step
                    await updateFlowHandler({ first_step_id: createdStep.id });
                }
                return [...flowSteps!, createdStep];
            },
            {
                optimisticData: [...flowSteps!, newStep],
                revalidate: true,
                rollbackOnError: true,
                populateCache: true,
            }
        );
    }

    const deleteStepHandler = (elementId: string) => {
        if (elementId.includes("edge_")) {
            const elements = elementId.split("_");
            const stepIndex = flowSteps.findIndex(
                (step) => step.id === elements[1]
            );
            updateStepHandler(elements[1], {
                next_steps: flowSteps[stepIndex].next_steps.map((stepSlot) => {
                    if (stepSlot.id === elements[2]) {
                        return { ...stepSlot, step_id: undefined };
                    }
                    return stepSlot;
                }),
            });
        } else {
            mutateFlowSteps(
                async () => {
                    await deleteStep(elementId);
                    return [...flowSteps!].filter(
                        (step) => step.id !== elementId
                    );
                },
                {
                    optimisticData: [...flowSteps!].filter(
                        (step) => step.id !== elementId
                    ),
                    revalidate: true,
                    rollbackOnError: true,
                    populateCache: true,
                }
            );
        }
    };

    async function runCurrentFlow() {
        setFlowIsRunning(true);
        const flowRunResult = await testFlow(flow!.id);
        setflowRunResult(flowRunResult);
        setFlowIsRunning(false);
    }

    const flowHasFirstStep =
        flowSteps.find((step) => step.id == flow.first_step_id) != undefined;

    return (
        <div>
            <Dialog
                content={dialogContent}
                setDialogContent={setDialogContent}
            />
            <FlowPageSettings
                setDialogContent={setDialogContent}
                hidden={settingsHidden}
                closeHandler={() => setSettingsHidden(true)}
                flow={flow}
            />
            <FlowPageControlBar
                flowRunResult={flowRunResult}
                flowHasFirstStep={flowHasFirstStep}
                runHandler={runCurrentFlow}
                cleanHandler={() => setflowRunResult(null)}
                settingsHandler={() => setSettingsHidden(!settingsHidden)}
                flowIsRunning={flowIsRunning}
            />
            <FlowPageEditor
                stepDefs={stepDefs}
                flow={flow}
                flowSteps={flowSteps}
                flowRunResult={flowRunResult}
                stateSlots={flowStateSlots}
                onElementWasDropped={createStepHandler}
                onElementsWereSelected={setSeletedStepId}
                onElementsWereRemoved={deleteStepHandler}
                onElementsWereUpdated={updateStepHandler}
                onFlowWasUpdated={updateFlowHandler}
            />
            <FlowPageInspector
                drawerShown={!drawerHidden}
                stepDefs={stepDefs}
                stateSlots={flowStateSlots}
                step={
                    selectedStepId
                        ? flowSteps.find((step) => step.id === selectedStepId)
                        : undefined
                }
                flowRunResult={flowRunResult}
                onStepWasUpdated={updateStepHandler}
                setDialogContent={setDialogContent}
            />
            <FlowPageStepDrawer
                stepDefs={stepDefs}
                flow={flow}
                hidden={stepDefs && drawerHidden}
                toggleHidden={() => setDrawerHidden(!drawerHidden)}
            />
        </div>
    );
}
