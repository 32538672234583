import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Nav from "./Nav";
import FlowsPage from "./FlowsPage";
import FlowPage from "./FlowPage";
import FlowShare from "./FlowShare";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

import { User } from "../data/users/models";
import { UserContext } from "../data/users/context";

import "./styles.scss";
import FullScreen from "./FullScreen";
import { stitchAPI } from "../data/fetcher";

export function App(): JSX.Element {
    const [user, setUser] = useState<User | null>(null);
    const [didPreFlight, setDidPreFlight] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!user && token) {
            stitchAPI<User>("/auth/", {
                method: "GET",
            })
                .then((userResponse) => {
                    setUser(userResponse);
                })
                .finally(() => {
                    setDidPreFlight(true);
                });
        } else {
            setDidPreFlight(true);
        }
    }, []);

    if (!didPreFlight) {
        return <FullScreen title="Loading" />;
    }

    return (
        <UserContext.Provider value={{ user, setUser }}>
            <BrowserRouter>
                <Nav />
                <Routes>
                    <Route path="/" element={<FlowsPage />} />
                    <Route path="/flows/:flowId/" element={<FlowPage />} />
                    <Route path="/share/:shareId/" element={<FlowShare />} />
                    <Route path="/sign-in/" element={<SignIn />} />
                    <Route path="/sign-up/" element={<SignUp />} />
                    <Route
                        path="*"
                        element={<FullScreen title="Not Found" />}
                    />
                </Routes>
            </BrowserRouter>
        </UserContext.Provider>
    );
}
