import React, { useContext, useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { stitchAPI } from "../../data/fetcher";
import { UserContext } from "../../data/users/context";
import { User } from "../../data/users/models";
import FullScreen from "../FullScreen";

interface SignInResponse {
    token: string;
}

export default function SignIn(): JSX.Element {
    const { user, setUser } = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const [email, setEmail] = React.useState(searchParams.get("email") || "");
    const [password, setPassword] = React.useState("");
    const [apiErrorMsg, setApiErrorMsg] = React.useState<null | string>(null);

    async function handleSignIn() {
        try {
            const tokenResponse = await stitchAPI<SignInResponse>(
                "/auth/token/",
                {
                    method: "GET",
                    headers: {
                        Authorization: `Basic ${btoa(email + ":" + password)}`,
                    },
                }
            );
            localStorage.setItem("token", tokenResponse.token);

            const userResponse = await stitchAPI<User>("/auth/", {
                method: "GET",
            });
            setUser(userResponse);
        } catch (e) {
            setApiErrorMsg(e.message);
        }
    }

    if (user) {
        return <Navigate to={"/"} />;
    }

    return (
        <FullScreen className="SignIn">
            <div className="Section Section--title">
                <h1>Sign In</h1>
            </div>
            {apiErrorMsg && (
                <div className="Section Section--result_error">
                    <div className="Section__title">Error</div>
                    <div className="Section__debug">
                        <div className="Section__debug__description">
                            {apiErrorMsg}
                        </div>
                    </div>
                </div>
            )}
            <div className="Section">
                <div className="UIInputCollection">
                    <div className="UIInput">
                        <label htmlFor="user_email">Email</label>
                        <input
                            autoFocus
                            className="input"
                            type="email"
                            id="user_email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="my@email.com"
                        />
                    </div>
                    <div className="UIInput">
                        <label htmlFor="user_password">Password</label>
                        <input
                            className="input"
                            type="password"
                            id="user_password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="••••••••••••"
                        />
                    </div>
                    <button className="UIButton" onClick={handleSignIn}>
                        Sign In
                    </button>
                    <div className="UIInputCollection__info">
                        Don't have an account? <a href="/sign-up/">Sign Up</a>
                    </div>
                </div>
            </div>
        </FullScreen>
    );
}
