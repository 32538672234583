import React from "react";

import "./styles.scss";

import { FlowPageStepDrawerStep } from "./FlowPageStepDrawerStep";

export const FlowPageStepDrawer = (props) => {
    return (
        <div
            id={"Drawer"}
            className={`pane ${props.hidden ? "hidden" : ""}`}
            onClick={props.toggleHidden}
        >
            {props.stepDefs
                .filter((sd) => !sd.type.includes(props.flow.id))
                .map((stepDef, i) => {
                    return <FlowPageStepDrawerStep {...stepDef} key={i} />;
                })}
        </div>
    );
};
