import React from "react";
import "./styles.scss";

interface FullScreenProps {
    className?: string;
    title?: string;
    description?: string;
    children?: React.ReactNode;
}

export default function FullScreen(props: FullScreenProps) {
    const { className, children, title, description } = props;

    return (
        <div className={className ? `FullScreen ${className}` : "FullScreen"}>
            <div className="FullScreen__content">
                {title && (
                    <div className="FullScreen__content__title">{title}</div>
                )}
                {description && (
                    <div className="FullScreen__content__description">
                        {description}
                    </div>
                )}
                {children}
            </div>
        </div>
    );
}
