import React, { useState, useEffect, useContext } from "react";
import { Navigate } from "react-router-dom";
import useSWR from "swr";
import { Dialog } from "../Dialog";
import { FlowsPageFlowGrid } from "./FlowsPageFlowGrid";
import { stitchAPI } from "../../data/fetcher";
import { createFlow, deleteFlow } from "../../data/flows/controllers";
import { Flow } from "../../data/flows/models";
import FullScreen from "../FullScreen";
import { UserContext } from "../../data/users/context";

export default function FlowsPage() {
    const { user } = useContext(UserContext);
    const [createdFlow, setCreatedFlow] = useState<null | any>(null);
    const [dialogContent, setDialogContent] = useState<JSX.Element | null>(
        null
    );

    const { data: flows, error } = useSWR<Flow[], Error>(
        `/flows/`,
        async (route: string) => {
            const data: { flows: Flow[] } = await stitchAPI(route);
            return data.flows;
        }
    );

    async function createNewFlow() {
        const createdFlow = await createFlow();
        setCreatedFlow(createdFlow);
    }

    if (!user) {
        return <Navigate to={`/sign-in/`} />;
    }

    if (!flows) {
        return <FullScreen title="Loading" />;
    }

    if (error) {
        return <FullScreen title="Failed to load Flows" />;
    }

    if (createdFlow) {
        return <Navigate to={`/flows/${createdFlow.id}/`} />;
    }

    return (
        <div>
            <Dialog
                content={dialogContent}
                setDialogContent={setDialogContent}
            />
            <FlowsPageFlowGrid
                flows={flows!}
                handleCreate={createNewFlow}
                handleDelete={deleteFlow}
                setDialogContent={setDialogContent}
            />
        </div>
    );
}
