import { mutate } from "swr";
import { stitchAPI } from "../fetcher";
import { Flow, FlowRunResult } from "./models";

export const testFlow = async (flowId: string): Promise<FlowRunResult> => {
    const response = await stitchAPI<{ result: FlowRunResult }>(
        `/flows/${flowId}/test/`
    );
    return response.result;
};

export async function updateFlow(
    flowId: string,
    updates: Partial<Flow>
): Promise<Flow> {
    const updatedFlow = await stitchAPI<Flow>(`/flows/${flowId}/`, {
        method: "PATCH",
        body: JSON.stringify(updates),
    });
    mutate(`/flows/`);
    return updatedFlow;
}

export async function createFlow(): Promise<Flow> {
    const createdFlow = await stitchAPI<Flow>("/flows/", {
        method: "POST",
        body: JSON.stringify({
            type: "webhook",
        }),
    });
    mutate("/flows/");
    return createdFlow;
}

export async function deleteFlow(flowId: string) {
    await stitchAPI(`/flows/${flowId}/`, {
        method: "DELETE",
    });
    mutate("/flows/");
}
