import React from "react";

import "./styles.scss";

interface MenuItem {
    label: string;
    icon: string;
    isDestructive?: boolean;
    disabled?: boolean;
    onClick?: (string) => void;
}

interface MenuSection {
    items: MenuItem[];
    footerText?: string;
}

interface ContextMenuProps {
    sections: MenuSection[];
    position?: { x: number; y: number };
    onClose?: () => void;
}

export function ContextMenu(props: ContextMenuProps) {
    const { sections, position, onClose } = props;

    if (!position) {
        return null;
    }

    return (
        <div
            className="Menu ContextMenu"
            style={{
                top: position.y,
                left: position.x,
            }}
        >
            {sections.map((section, sectionIndex) => (
                <div className="Menu__section" key={sectionIndex}>
                    {section.items.map((item, itemIndex) => (
                        <div
                            key={itemIndex}
                            className={`Menu__section__item ${
                                item.disabled
                                    ? "Menu__section__item--disabled"
                                    : ""
                            } ${
                                item.isDestructive
                                    ? "Menu__section__item--destructive"
                                    : ""
                            }`}
                            onClick={() => {
                                if (item.onClick) {
                                    item.onClick(item.label);
                                }
                                if (onClose) {
                                    onClose();
                                }
                            }}
                        >
                            <div
                                className={`Menu__section__item__icon Menu__section__item__icon--${item.icon}`}
                            />
                            <div className="Menu__section__item__label">
                                {item.label}
                            </div>
                        </div>
                    ))}
                    {section.footerText && (
                        <div className="Menu__section__footer">
                            {section.footerText}
                        </div>
                    )}
                    <div className="Menu__section__divider" />
                </div>
            ))}
        </div>
    );
}
