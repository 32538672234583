import React, { useState } from "react";

import "./styles.scss";

interface DialogProps {
    content: JSX.Element | null;
    setDialogContent: (content: JSX.Element | null) => void;
}

export const Dialog = (props: DialogProps) => {
    const { content, setDialogContent } = props;
    const hidden = content === null;

    return (
        <div
            className={`Modal Modal--dialog ${hidden && "Modal--hidden"}`}
            onClick={(e) => {
                setDialogContent(null);
                e.stopPropagation();
            }}
        >
            <div className="Dialog" onClick={(e) => e.stopPropagation()}>
                {content}
            </div>
        </div>
    );
};
