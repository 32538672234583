import { API_FQD } from "../constants";

const DESCRIPTIONS: { [id: number]: string } = {
    401: "Unauthorized",
    403: "Forbidden",
    404: "Not Found",
};

export class AuthError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "AuthError";
    }
}

export class ValidationError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "ValidationError";
    }
}

export async function stitchAPI<T>(
    path: string,
    options?: RequestInit
): Promise<T> {
    const url = `${API_FQD}${path}`;
    const token = localStorage.getItem("token");

    if (options === undefined) {
        options = {};
    }

    // Ensure that the right headers are added
    if (options.headers === undefined) {
        options.headers = {
            "Content-Type": "application/json",
        };
    } else {
        options.headers["Content-Type"] = "application/json";
    }

    // Add the token to the headers, if present in LocalStorage and there isn't already an Authorization header (for sign in, for example this should not be the token)
    if (token && options.headers["Authorization"] === undefined) {
        options.headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await fetch(url, options);

    if (!response.ok) {
        switch (response.status) {
            case 401:
            case 403:
                localStorage.removeItem("token");
                window.location.href = "/sign-in";
            case 400:
                const data = await response.json();
                throw new ValidationError(
                    JSON.stringify(data.validation_error)
                );
            default:
                throw new Error(
                    DESCRIPTIONS[response.status] || "Unknown Error"
                );
        }
    } else {
        return await response.json();
    }
}
