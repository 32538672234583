import React from "react";
import { User } from "./models";

export const UserContext = React.createContext<{
    user: User | null;
    setUser: (user: User | null) => void;
}>({
    user: null,
    setUser: () => {},
});
