import React, { useEffect } from "react";
import { Step, StepDef } from "../../../data/steps/models";
import { StateSlot } from "../../../data/state_slots/models";
import { FlowRunResult, StepRunRow } from "../../../data/flows/models";
import { StateSlot as StateSlotComp } from "../../StateSlot";
import { UIInputCollection } from "../../UIInputCollection";

import "./styles.scss";

interface FlowPageInspectorProps {
    onStepWasUpdated: (stepId: string, updates: Partial<Step>) => void;
    setDialogContent: (content: JSX.Element) => void;
    step: Step | undefined;
    stepDefs: StepDef[];
    stateSlots: StateSlot[];
    flowRunResult: FlowRunResult | null;
    drawerShown: boolean;
}

export function FlowPageInspector(props: FlowPageInspectorProps): JSX.Element {
    const {
        onStepWasUpdated,
        setDialogContent,
        step,
        stepDefs,
        stateSlots,
        flowRunResult,
        drawerShown,
    } = props;

    const [stepDef, setStepDef] = React.useState<StepDef | null>(null);
    const [hidden, setHidden] = React.useState<boolean>(false);
    const [stepRunResult, setStepRunResult] = React.useState<
        StepRunRow | undefined
    >(undefined);

    useEffect(() => {
        if (!step) {
            return;
        }
        const stepDef = stepDefs.find((sd) => {
            if (sd.type.includes("flow_alias")) {
                return sd.type === `flow_alias:${step.run_flow_id}`;
            }

            return sd.type === step.type;
        });

        if (stepDef) {
            setStepDef(stepDef);
        }
    }, [step, stepDefs]);

    useEffect(() => {
        setHidden(!step);
    }, [step]);

    useEffect(() => {
        if (flowRunResult) {
            setStepRunResult(
                flowRunResult.step_results.find(
                    (r) => step && r[0].step_id === step.id
                )
            );
        } else {
            setStepRunResult(undefined);
        }
    }, [step, flowRunResult]);

    return (
        <div
            id="FlowPageInspector"
            className={`pane ${hidden ? "hidden" : "shown"} ${
                drawerShown ? "shorter" : ""
            }`}
        >
            {step && (
                <div>
                    <div className={"FlowPageEditorStep__header"}>
                        <div
                            className={`FlowPageEditorStep__header__icon ${
                                stepDef && stepDef.type.includes("flow_alias")
                                    ? "FlowPageEditorStep__header__icon--flow_alias"
                                    : ""
                            }`}
                            style={{
                                backgroundColor: stepDef
                                    ? stepDef.color_hex
                                    : "grey",
                            }}
                        ></div>
                        <div className={"FlowPageEditorStep__header__label"}>
                            {stepDef ? stepDef.name : "Unknown Step Type"}
                        </div>
                    </div>
                    {stepRunResult && (
                        <div
                            className={`Section Section--result_${stepRunResult[0].status}`}
                        >
                            <div className={"Section__title"}>
                                {stepRunResult[0].status}
                            </div>
                            {(stepRunResult[1] || stepRunResult[0].error) && (
                                <div className={"Section__debug"}>
                                    {stepRunResult[0].error &&
                                        stepRunResult[0].error.description && (
                                            <div
                                                className={
                                                    "Section__debug__description"
                                                }
                                            >
                                                {
                                                    stepRunResult[0].error
                                                        .description
                                                }
                                            </div>
                                        )}

                                    {stepRunResult[1] && (
                                        <table className="Section__debug__state-table">
                                            <tbody>
                                                {Object.entries(
                                                    stepRunResult[1]
                                                ).map((entry, index) => (
                                                    <tr
                                                        key={`${entry[0]}_${index}`}
                                                    >
                                                        <td>
                                                            {
                                                                <StateSlotComp
                                                                    {...(stateSlots.find(
                                                                        (s) => {
                                                                            return (
                                                                                s.id ===
                                                                                entry[0]
                                                                            );
                                                                        }
                                                                    ) || {
                                                                        id: "no-id",
                                                                        name: "Missing",
                                                                        scope: "unknown",
                                                                        type: "anything",
                                                                    })}
                                                                />
                                                            }
                                                        </td>
                                                        <td>
                                                            {JSON.stringify(
                                                                entry[1]
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                    {stepRunResult && <div className={"SectionBreak"}></div>}
                    {step.inputs && step.inputs.length > 0 && (
                        <div className={"Section"}>
                            <div className={"Section__title"}>Inputs</div>
                            <UIInputCollection
                                setDialogContent={setDialogContent}
                                inputs={step.inputs}
                                step={step}
                                stateSlots={stateSlots}
                                onChange={(newInputObj, index) => {
                                    const newInputs = [...step.inputs];
                                    newInputs[index] = newInputObj;
                                    onStepWasUpdated(step.id, {
                                        inputs: newInputs,
                                    });
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
