import React, { useContext, useEffect } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";

import { Flow } from "../../data/flows/models";
import { stitchAPI } from "../../data/fetcher";
import { API_FQD } from "../../constants";
import FullScreen from "../FullScreen";
import { UserContext } from "../../data/users/context";

export default function FlowShare() {
    const { user } = useContext(UserContext);
    const { shareId } = useParams();
    const navigate = useNavigate();

    const { data: flow, error: flowError } = useSWR<Flow, Error>(
        shareId ? `/flows/share/${shareId}/` : null,
        stitchAPI
    );

    useEffect(() => {
        document.title = flow && flow.name ? `Stitch - ${flow.name}` : "Stitch";
    }, [flow]);

    if (!shareId) {
        // No URL flow ID, so user should make a new flow
        return <Navigate to="/" />;
    }

    if (flowError) {
        return <FullScreen title="Error" description={flowError.message} />;
    }

    if (!flow) {
        return <FullScreen title="Loading" />;
    }

    return (
        <div>
            <FullScreen className="FlowsPageFlowGrid">
                <div
                    className="FlowsPageFlowGrid__inner__flow"
                    key={flow.id}
                    id={flow.id}
                >
                    <h2>A Flow has been shared with you</h2>
                    <div
                        className="FlowsPageFlowGrid__inner__flow__preview"
                        style={{
                            backgroundImage: `url('${API_FQD}/flows/${flow.id}/preview.png')`,
                        }}
                    ></div>
                    <div
                        className={`FlowsPageFlowGrid__inner__flow__name ${
                            flow.name ||
                            "FlowsPageFlowGrid__inner__flow__name--untitled"
                        }`}
                    >
                        {flow.name || "Untitled Flow"}
                    </div>
                    <button
                        className="UIButton"
                        disabled={!user}
                        onClick={async () => {
                            await stitchAPI(`/flows/${flow.id}/duplicate/`, {
                                method: "POST",
                            });
                            mutate("/flows/");
                            navigate("/");
                        }}
                    >
                        Copy Flow to my Project
                    </button>
                    {!user && (
                        <span>
                            You'll need to Sign In to copy this Flow to your
                            Project
                        </span>
                    )}
                </div>
            </FullScreen>
        </div>
    );
}
