import React from "react";
import { FlowRunResult } from "../../../data/flows/models";

import "./styles.scss";

interface FlowPageControlBarProps {
    flowRunResult: FlowRunResult | null;
    flowHasFirstStep: boolean;
    cleanHandler: () => void;
    runHandler: () => void;
    settingsHandler: () => void;
    flowIsRunning: boolean;
}

export function FlowPageControlBar(
    props: FlowPageControlBarProps
): JSX.Element {
    const {
        flowRunResult,
        flowHasFirstStep,
        cleanHandler,
        runHandler,
        settingsHandler,
        flowIsRunning,
    } = props;

    return (
        <div className="RunBar">
            <div className="RunBar__wrapper">
                <div className="RunBar__wrapper__buttons">
                    <button className={`settings`} onClick={settingsHandler} />
                    <button
                        className={`play ${!flowHasFirstStep && "disabled"}`}
                        onClick={runHandler}
                    />
                    <button
                        className={`clean ${!flowRunResult && "disabled"}`}
                        onClick={cleanHandler}
                    />
                </div>
                <div className="RunBar__wrapper__status">
                    {flowIsRunning
                        ? "Running"
                        : flowRunResult
                        ? flowRunResult.status
                        : flowHasFirstStep
                        ? "Ready"
                        : "No First Step"}
                </div>
            </div>
        </div>
    );
}
