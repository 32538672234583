import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { stitchAPI, ValidationError } from "../../data/fetcher";
import { UserContext } from "../../data/users/context";
import FullScreen from "../FullScreen";

interface Org {
    id: string;
    date_created: string;
    name: string;
}

interface User {
    id: string;
    date_created: string;
    email: string;
    org_id: string;
    password_hash?: string;
}

interface SignUpResponse {
    org: Org;
    user: User;
}

const locInputdMap = {
    user_email: "Email",
    user_password: "Password",
    org_name: "Project Name",
};

export default function SignUp(): JSX.Element {
    const { user } = useContext(UserContext);
    const [email, setEmail] = React.useState("");
    const [validEmail, setValidEmail] = React.useState<null | boolean>(null);
    const [orgName, setOrgName] = React.useState("My Project");
    const [validOrgName, setValidOrgName] = React.useState<null | boolean>(
        null
    );
    const [password, setPassword] = React.useState("");
    const [validPassword, setValidPassword] = React.useState<null | boolean>(
        null
    );
    const [validForm, setValidForm] = React.useState<null | boolean>(null);
    const [apiErrorMsg, setApiErrorMsg] = React.useState<null | string>(null);
    const [signUpResponse, setSignUpResponse] =
        React.useState<null | SignUpResponse>(null);

    useEffect(() => {
        setValidEmail(email === "" ? null : email.length >= 5);
    }, [email, apiErrorMsg]);

    useEffect(() => {
        setValidPassword(password === "" ? null : password.length >= 12);
    }, [password]);

    useEffect(() => {
        setValidOrgName(orgName === "" ? null : orgName.length >= 2);
    }, [orgName]);

    useEffect(() => {
        setValidForm(validEmail && validPassword && validOrgName);
    }, [validEmail, validPassword, validOrgName]);

    function handleSignUp() {
        stitchAPI<SignUpResponse>("/sign_up/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_email: email,
                user_password: password,
                org_name: orgName,
            }),
        })
            .then((response: SignUpResponse) => {
                setSignUpResponse(response);
            })
            .catch((e) => {
                if (e instanceof ValidationError) {
                    const validationError = JSON.parse(e.message);
                    const fullErrorMessage = validationError.body_params
                        .map((e) => `${locInputdMap[e.loc]}: ${e.msg}.`)
                        .join("\n\n");
                    setApiErrorMsg(fullErrorMessage);
                } else {
                    setApiErrorMsg(e.message);
                    setValidForm(false);
                }
            });
    }

    if (user) {
        return <Navigate to={"/"} />;
    }

    if (signUpResponse) {
        return (
            <Navigate
                to={"/sign-in/" + `?email=${signUpResponse.user.email}`}
            />
        );
    }

    return (
        <FullScreen className="SignUp">
            <div className="Section Section--title">
                <h1>Sign Up</h1>
            </div>
            {apiErrorMsg && (
                <div className="Section Section--result_error">
                    <div className="Section__title">Error</div>
                    <div className="Section__debug">
                        <div className="Section__debug__description">
                            {apiErrorMsg}
                        </div>
                    </div>
                </div>
            )}
            <div className="Section">
                <div className="UIInputCollection">
                    <div className="UIInput">
                        <label htmlFor="org_name">Project Name</label>
                        <input
                            className="input"
                            type="text"
                            id="org_name"
                            value={orgName}
                            onChange={(e) => setOrgName(e.target.value)}
                            placeholder="My Project"
                        />
                    </div>
                    <div className="UIInput">
                        <label htmlFor="user_email">Email</label>
                        <input
                            autoFocus
                            className="input"
                            type="email"
                            id="user_email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="my@email.com"
                        />
                    </div>
                    <div className="UIInput">
                        <label htmlFor="user_password">Password</label>
                        <input
                            className={
                                validPassword === false
                                    ? "input input--invalid"
                                    : "input"
                            }
                            type="password"
                            id="user_password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="••••••••••••"
                        />
                    </div>
                    {validPassword === false && (
                        <div className="UIInputCollection__info UIInputCollection__info--error">
                            A strong password must be at least 12 characters
                            long
                        </div>
                    )}
                    <button
                        className="UIButton"
                        disabled={!validForm}
                        onClick={handleSignUp}
                    >
                        Sign Up
                    </button>
                    <div className="UIInputCollection__info">
                        Already have an account? <a href="/sign-in">Sign In</a>
                    </div>
                </div>
            </div>
        </FullScreen>
    );
}
