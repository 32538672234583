// TODO: Refactor with FlowPageEditorStep

import React from "react";

export const FlowPageStepDrawerStep = (props) => {
    const onDragStart = (e) => {
        e.dataTransfer.effectAllowed = "move";
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        e.dataTransfer.setData(
            "application/reactflow",
            JSON.stringify({
                type: props.type,
                clickPos: { x, y },
            })
        );
    };

    return (
        <div
            className={"FlowPageEditorStep"}
            onClick={(e) => {
                e.stopPropagation();
            }}
            draggable
            onDragStart={onDragStart}
            title={props.description}
        >
            <div className={"FlowPageEditorStep__header"}>
                <div
                    className={`FlowPageEditorStep__header__icon ${
                        props.type.includes("flow_alias")
                            ? "FlowPageEditorStep__header__icon--flow_alias"
                            : ""
                    }`}
                    style={{ backgroundColor: props.color_hex }}
                />
                <div className={"FlowPageEditorStep__header__label"}>
                    {props.name}
                </div>
            </div>
            <div className={"FlowPageEditorStep__output"}>
                <div className={"FlowPageEditorStep__output__label"}></div>
            </div>
            <div className={"FlowPageEditorStep__output"}>
                <div className={"FlowPageEditorStep__output__label"}></div>
            </div>
        </div>
    );
};
