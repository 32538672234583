import { stitchAPI } from "../fetcher";
import { StateSlot } from "./models";

export const createStateSlot = async (
    stateSlot: StateSlot
): Promise<StateSlot> => {
    return await stitchAPI<StateSlot>("/state_slots/", {
        method: "POST",
        body: JSON.stringify({
            flow_id: stateSlot.flow_id,
            step_id: stateSlot.step_id,
            type: stateSlot.type,
            name: stateSlot.name,
        }),
    });
};
