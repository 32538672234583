import React from "react";
import { StateSlot } from "../../data/state_slots/models";
import { Step } from "../../data/steps/models";

import { UIInputSingleText } from "../UIInputSingleText";
import { UIInputState } from "../UIInputState";
import { UIInputList } from "../UIInputList";

import "./styles.scss";

interface UIInputCollectionProps {
    onChange: (value: any, i: number) => void;
    inputs: any[];
    showLabels?: boolean;
    setDialogContent: (content: JSX.Element) => void;
    showAsRow?: boolean;
    step: Step;
    stateSlots: StateSlot[];
}

export function UIInputCollection(props: UIInputCollectionProps): JSX.Element {
    const {
        onChange,
        inputs,
        showLabels,
        setDialogContent,
        showAsRow,
        step,
        stateSlots,
    } = props;

    // TODO: Refactor this and list.jsx to use the same code

    const renderInput = (input, i) => {
        const commonProps = {
            step,
            stateSlots,
            input: input,
            setDialogContent: setDialogContent,
            onChange: (newValue: any) =>
                onChange({ ...input, value: newValue }, i),
        };

        if (input.hidden) {
            return null;
        }

        if (input.depends_on.length) {
            const matches: any[] = [];

            input.depends_on.forEach((rule) => {
                // Find the first rule match and then escape if a match is found
                const depInput = inputs.find((i) => i.key === rule[0]);

                if (depInput && depInput.value === rule[1]) {
                    matches.push(rule);
                }
            });

            if (!matches.length) {
                return undefined;
            }
        }

        switch (input.type) {
            case "input_text":
            case "input_anything":
                return <UIInputSingleText {...commonProps} />;
            case "input_list_state":
            case "input_list_inputs":
                return <UIInputList {...commonProps} />;
            case "input_state":
                return <UIInputState {...commonProps} />;

            default:
                return (
                    <div
                        className="UIInput__alert"
                        key={`${step.id}_${input.key}_${i}`}
                    >{`Unimplemented: ${input.type}`}</div>
                );
        }
    };

    const renderRow = (input: any, i: number) => {
        const inputElement = renderInput(input, i);

        if (!inputElement) {
            return undefined;
        }

        return (
            <div className="UIInput" key={`${step.id}_${input.key}_${i}`}>
                {showLabels != false && (
                    <label htmlFor={input.key}>{input.name}</label>
                )}
                {inputElement}
            </div>
        );
    };

    return (
        <div
            className={`UIInputCollection ${
                showAsRow ? "UIInputCollection--row" : ""
            }`}
        >
            {inputs.map((input, i) => {
                return renderRow(input, i);
            })}
        </div>
    );
}
