import React, { SyntheticEvent, useState } from "react";
import { Flow } from "../../../data/flows/models";
import { useNavigate } from "react-router-dom";
import { ContextMenu } from "../../ContextMenu";

import "./styles.scss";
import { API_FQD } from "../../../constants";
import { stitchAPI } from "../../../data/fetcher";
import { mutate } from "swr";
import { updateFlow } from "../../../data/flows/controllers";

interface FlowsPageFlowGridFlowPreviewProps {
    flow: Flow;
    contextShown: boolean;
    handleToggleNodeContextMenu: (e: SyntheticEvent, flowId: string) => void;
}

function FlowsPageFlowGridFlowPreview(
    props: FlowsPageFlowGridFlowPreviewProps
) {
    const { flow, contextShown, handleToggleNodeContextMenu } = props;
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [flowName, setFlowName] = useState<string | null>(flow.name);
    const navigate = useNavigate();

    return (
        <div
            className="FlowsPageFlowGrid__inner__flow"
            key={flow.id}
            id={flow.id}
        >
            <div
                className="FlowsPageFlowGrid__inner__flow__preview"
                style={{
                    backgroundImage: `url('${API_FQD}/flows/${flow.id}/preview.png')`,
                }}
                onClick={() => {
                    !contextShown && navigate(`/flows/${flow.id}/`);
                }}
                onContextMenu={(e) => handleToggleNodeContextMenu(e, flow.id)}
            ></div>
            <div
                className={`FlowsPageFlowGrid__inner__flow__name ${
                    !flow.name &&
                    !isEditing &&
                    "FlowsPageFlowGrid__inner__flow__name--untitled"
                }`}
                onClick={() => setIsEditing(true)}
            >
                {isEditing ? (
                    <div className="FlowsPageFlowGrid__inner__flow__name__input UIInput">
                        <input
                            value={flowName || ""}
                            onChange={(e) => {
                                setFlowName(e.target.value);
                            }}
                            onFocus={(e) => {
                                e.target.select();
                            }}
                            className="input"
                            autoFocus={true}
                            onBlur={(e) => {
                                setIsEditing(false);
                                updateFlow(flow.id, {
                                    name: e.target.value,
                                }).then(() => {
                                    mutate(`/flows/${flow.id}/`);
                                });
                            }}
                            placeholder="Untitled Flow"
                        />
                    </div>
                ) : (
                    <>{flowName || "Untitled Flow"}</>
                )}
            </div>
        </div>
    );
}

interface FlowsPageFlowGridProps {
    handleCreate: () => void;
    handleDelete: (flowId: string) => void;
    flows: Flow[];
    setDialogContent: (content: JSX.Element | null) => void;
}

export function FlowsPageFlowGrid(props: FlowsPageFlowGridProps) {
    const { handleCreate, handleDelete, flows, setDialogContent } = props;

    // When a user right-clicks anywhere in the editor, disable the default behaviour
    // and use our custom one
    const [contextMenuPosition, setContextMenuPosition] = useState<any | null>({
        x: 0,
        y: 0,
    });
    const [contextMenuFlowId, setContextMenuFlowId] = useState<string | null>(
        null
    );

    const handleToggleNodeContextMenu = (e: any, flowId: string) => {
        e.preventDefault();
        setContextMenuPosition({
            x: e.clientX,
            y: e.clientY,
        });
        setContextMenuFlowId(flowId);
    };

    return (
        <div
            className="FlowsPageFlowGrid"
            onClick={(_) => {
                setContextMenuFlowId(null);
            }}
            onScroll={() => setContextMenuFlowId(null)}
        >
            {contextMenuFlowId && (
                <ContextMenu
                    position={contextMenuPosition}
                    sections={[
                        {
                            items: [
                                {
                                    label: "Duplicate",
                                    icon: "copy",
                                    isDestructive: false,
                                    onClick: async () => {
                                        await stitchAPI(
                                            `/flows/${contextMenuFlowId}/duplicate/`,
                                            {
                                                method: "POST",
                                            }
                                        );
                                        mutate("/flows/");
                                    },
                                },
                                {
                                    label: "Delete",
                                    icon: "delete",
                                    isDestructive: true,
                                    onClick: () => {
                                        const flow = flows.find(
                                            (flow) =>
                                                flow.id === contextMenuFlowId
                                        );
                                        setDialogContent(
                                            <div className="Dialog__content UIInputCollection">
                                                <div className="Dialog__content__title">
                                                    Delete Flow
                                                </div>
                                                <div className="Dialog__content__description">
                                                    {`Are you sure you want to
                                                    delete ${
                                                        flow!.name ||
                                                        "this flow"
                                                    }?`}
                                                </div>
                                                <button
                                                    className="UIButton UIButton--destructive"
                                                    onClick={() => {
                                                        handleDelete(
                                                            contextMenuFlowId
                                                        );
                                                        setDialogContent(null);
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                                <button
                                                    className="UIButton UIButton--light"
                                                    onClick={() => {
                                                        setDialogContent(null);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        );
                                    },
                                },
                            ],
                        },
                    ]}
                    onClose={() => {
                        setContextMenuPosition(null);
                        setContextMenuFlowId(null);
                    }}
                />
            )}
            <div className="FlowsPageFlowGrid__inner">
                <button
                    className="FlowsPageFlowGrid__inner__create"
                    onClick={() => !contextMenuFlowId && handleCreate()}
                />
                {flows.map((flow) => (
                    <FlowsPageFlowGridFlowPreview
                        flow={flow}
                        contextShown={contextMenuFlowId !== null}
                        handleToggleNodeContextMenu={
                            handleToggleNodeContextMenu
                        }
                    />
                ))}
            </div>
        </div>
    );
}
