import { stitchAPI } from "../fetcher";
import { Step } from "./models";

export async function createStep(step: Step): Promise<Step> {
    return await stitchAPI<Step>("/steps/", {
        method: "POST",
        body: JSON.stringify({
            flow_id: step.flow_id,
            type: step.type,
            position: step.position,
        }),
    });
}

export const deleteStep = async (stepId: string): Promise<boolean> => {
    const result = await stitchAPI<{ message: string }>(`/steps/${stepId}/`, {
        method: "DELETE",
    });
    return result.message.includes("deleted");
};

export const updateStep = async (
    stepId: string,
    data: Partial<Step>
): Promise<Step> => {
    return await stitchAPI<Step>(`/steps/${stepId}/`, {
        method: "PATCH",
        body: JSON.stringify(data),
    });
};
