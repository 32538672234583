import React, { useState, useEffect } from "react";
import ReactFlow from "react-flow-renderer";
import {
    Flow,
    FlowRunResult,
    StepRunResult,
    StepRunRow,
} from "../../../data/flows/models";
import { StateSlot } from "../../../data/state_slots/models";
import { StepDef, Step } from "../../../data/steps/models";

import { FlowPageEditorStep } from "./FlowPageEditorStep";
import { ContextMenu } from "../../ContextMenu";

import "./styles.scss";
import { useNavigate } from "react-router-dom";

interface FlowPageEditorProps {
    stepDefs: StepDef[];
    flow: Flow;
    flowSteps: Step[];
    flowRunResult: FlowRunResult | null;
    stateSlots: StateSlot[];
    onFlowWasUpdated: (updates: Partial<Flow>) => void;
    onElementsWereSelected: (stepId: string) => void;
    onElementWasDropped: (dropData: string, canvasPosition: any) => void;
    onElementsWereUpdated: (stepId: string, updates: Partial<Step>) => void;
    onElementsWereRemoved: (elementId: string) => void;
}

interface FlowPageEditorStep {
    id: string;
    type: string;
    position: {
        x: number;
        y: number;
    };
    data: {
        step: Step;
        stepDef: StepDef | undefined;
        stepRunResult: StepRunRow | undefined;
        isFirst: boolean;
    };
}

interface FlowPageEditorPath {
    id: string;
    source: string;
    sourceHandle: string;
    target: string;
    style: {
        stroke: string;
        strokeWidth: number;
    };
}

type FlowPageEditorElement = FlowPageEditorStep | FlowPageEditorPath;

export function FlowPageEditor(props: FlowPageEditorProps): JSX.Element {
    const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
    const [elements, setElements] = useState<FlowPageEditorElement[]>([]);
    const {
        stepDefs,
        flowSteps,
        flowRunResult,
        flow,
        onFlowWasUpdated,
        onElementsWereSelected,
        onElementWasDropped,
        onElementsWereUpdated,
        onElementsWereRemoved,
    } = props;

    const navigate = useNavigate();

    // When a user right-clicks anywhere in the editor, disable the default behaviour
    // and use our custom one
    const [contextMenuAnchorPoint, setContextMenuAnchorPoint] = useState({
        x: 0,
        y: 0,
    });
    const [contextMenuStepId, setContextMenuStepId] = useState(null);

    const handleToggleNodeContextMenu = (e, node) => {
        e.preventDefault();
        setContextMenuAnchorPoint({
            x: e.clientX,
            y: e.clientY,
        });
        setContextMenuStepId(node.id);
    };

    const connectionLineStyle = { stroke: "#aeaeb0", strokeWidth: 3 };

    // Whenever the flowSteps store is changed, update the graph's elements
    useEffect(() => {
        setElements(
            flowSteps.flatMap<any>((step) => {
                const els: FlowPageEditorElement[] = [];
                const stepsRun = flowRunResult
                    ? flowRunResult.step_results
                    : [];

                els.push({
                    id: step.id,
                    type: "step", // This is the React-Flow node type, not the Step type
                    position: {
                        x: step.position[0],
                        y: step.position[1],
                    },
                    data: {
                        // PERF: This data shouldn't be deeply nested into the element
                        step,
                        stepDef: stepDefs.find((sd) => {
                            if (sd.type.includes("flow_alias")) {
                                return (
                                    sd.type === `flow_alias:${step.run_flow_id}`
                                );
                            }

                            return sd.type === step.type;
                        }),
                        stepRunResult: stepsRun.find(
                            (sr) => sr[0].step_id === step.id
                        ),
                        isFirst: flow.first_step_id === step.id,
                    },
                });

                if (step.next_steps && step.next_steps.length) {
                    // Create any paths that connect the nodes
                    step.next_steps.map((slot) => {
                        // Sometimes nextStepId is null if the source isn't connected yet
                        if (slot.step_id) {
                            els.push({
                                id: `edge_${step.id}_${slot.id}_${slot.step_id}`,
                                source: step.id,
                                sourceHandle: slot.id,
                                target: slot.step_id,
                                style: connectionLineStyle,
                            });
                        }
                    });
                }

                return els;
            })
        );
    }, [flowSteps, flowRunResult, flow]);

    const onLoad = (_reactFlowInstance) => {
        setReactFlowInstance(_reactFlowInstance);
        _reactFlowInstance.fitView();
    };

    // NOTE: The DragStart event is defined in FlowPageEditorStep and bound to the FlowPageEditorStep component

    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    };

    const onDragDrop = (event) => {
        const dropData = JSON.parse(
            event.dataTransfer.getData("application/reactflow")
        );

        if (reactFlowInstance) {
            // Gets the position of the mouse relative to the canvas and without current zoom levels
            const canvasPosition = reactFlowInstance.project({
                x: event.clientX - dropData.clickPos.x,
                y: event.clientY - dropData.clickPos.y + 2,
            });
            onElementWasDropped(dropData.type, canvasPosition);
        }
    };

    const onConnect = (params) => {
        const fromStepId = params.source;
        const fromStepSlotId = params.sourceHandle;
        const toStepId = params.target;

        const step = flowSteps.find((step) => step.id === fromStepId)!;
        let newNextSteps = [...step.next_steps];

        newNextSteps.forEach((slot) => {
            if (slot.id === fromStepSlotId) {
                slot.step_id = toStepId;
            }
        });

        onElementsWereUpdated(fromStepId, { next_steps: newNextSteps });
    };

    const onElementClick = (_, element) => {
        // TOOD: Make this work with multiple nodes (multi copy/delete)
        onElementsWereSelected(element.id);
    };

    const onNodeDragStop = (event, node) => {
        onElementsWereUpdated(node.id, {
            position: [node.position.x, node.position.y],
        });
    };

    const contextMenuStep = flowSteps.find(
        (step) => step.id === contextMenuStepId
    );

    return (
        <div>
            {contextMenuStepId && contextMenuStep && (
                <ContextMenu
                    position={contextMenuAnchorPoint}
                    sections={[
                        {
                            items: [
                                {
                                    label: "Set as First Step",
                                    icon: "start-step",
                                    disabled:
                                        flow.first_step_id ===
                                        contextMenuStepId,
                                    onClick: () => {
                                        onFlowWasUpdated({
                                            first_step_id: contextMenuStepId,
                                        });
                                    },
                                },
                                ...(contextMenuStep.run_flow_id
                                    ? [
                                          {
                                              label: "Edit this Flow",
                                              icon: "flow",
                                              onClick: () => {
                                                  navigate(
                                                      `/flows/${contextMenuStep.run_flow_id}`
                                                  );
                                              },
                                          },
                                      ]
                                    : []),
                                {
                                    label: "Delete",
                                    icon: "delete",
                                    isDestructive: true,
                                    onClick: () => {
                                        onElementsWereRemoved(
                                            contextMenuStepId
                                        );
                                    },
                                },
                            ],
                        },
                    ]}
                    onClose={() => setContextMenuStepId(null)}
                />
            )}
            <ReactFlow
                id="FlowPageEditor"
                nodeTypes={{ step: FlowPageEditorStep }}
                onDragOver={onDragOver}
                connectionLineStyle={connectionLineStyle}
                maxZoom={1}
                onMoveStart={(e) => {
                    setContextMenuStepId(null);
                }}
                onDrop={onDragDrop}
                onLoad={onLoad}
                onElementClick={onElementClick}
                onSelectionChange={(selection) => {
                    setContextMenuStepId(null);

                    if (!selection || !selection.length) {
                        onElementsWereSelected("");
                    }
                }}
                onNodeDragStop={onNodeDragStop}
                onNodeContextMenu={handleToggleNodeContextMenu}
                onPaneClick={() => setContextMenuStepId(null)}
                onConnect={onConnect}
                onElementsRemove={(els) => onElementsWereRemoved(els[0].id)}
                elements={elements}
                style={{ width: "100vw", height: "100vh" }}
            />
        </div>
    );
}
