import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { UserContext } from "../../data/users/context";

import "./styles.scss";

export default function Nav() {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();

    function logout() {
        localStorage.removeItem("token");
        setUser(null);
    }

    return (
        <div className="Nav">
            <div className="Nav__inner">
                <div
                    className="Nav__inner__item Nav__inner__item--logo"
                    onClick={() => navigate("/")}
                />
                {user ? (
                    <div className="Nav__inner__item Nav__inner__item--right Nav__inner__item--user" />
                ) : (
                    <div className="Nav__inner__item Nav__inner__item--right">
                        <button
                            className="UIButton"
                            onClick={() => navigate("/sign-in/")}
                        >
                            Sign In
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
