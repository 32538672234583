import React, {
    useMemo,
    useCallback,
    useRef,
    useEffect,
    useState,
} from "react";
import { StateSlot } from "../../data/state_slots/models";
import { Editor, Transforms, Range, createEditor } from "slate";
import { withHistory } from "slate-history";
import {
    Slate,
    Editable,
    ReactEditor,
    withReact,
    useSelected,
    useFocused,
} from "slate-react";
import { StateSlot as StateSlotComp } from "../StateSlot";

const serialize = (nodes) => {
    return nodes
        .map((node) => {
            // paragraphs
            return node.children
                .map((child) => {
                    // text nodes
                    if (child.type === "slot") {
                        return `{{${child.slotId}}}`;
                    }

                    return child.text || "";
                })
                .join("");
        })
        .join("\n");
};

const deserialize = (text) => {
    return text.split("\n").map((line) => {
        return {
            type: "paragraph",
            children: line.split(/\{\{([a-f0-9\-]+)\}\}/gi).map((text) => {
                // Checks to see if this part of text is a UUID4 from the split operation
                // This is more specific than the regex above because the one above has {{}} around it so it can be less specific
                if (
                    text.match(
                        /[0-9a-f]{8}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{4}\-[0-9a-f]{12}/i
                    )
                ) {
                    return {
                        type: "slot",
                        slotId: text,
                        children: [{ text: "" }],
                    };
                }

                return {
                    text: text || "",
                };
            }),
        };
    });
};

// TODO: Refactor to StateslotPreview comp
const StateSlotInline = ({ attributes, children, element, stateSlots }) => {
    const selected = useSelected();
    const focused = useFocused();
    const slot = stateSlots.find((s) => s.id === element.slotId);

    return slot ? (
        <StateSlotComp {...slot} />
    ) : (
        <StateSlotComp
            id="temp-id"
            name="Missing"
            scope="unknown"
            type="anything"
        />
    );
};

const withMentions = (editor) => {
    // What does this do?
    const { isInline, isVoid } = editor;

    editor.isInline = (element) => {
        return element.type === "slot" ? true : isInline(element);
    };

    editor.isVoid = (element) => {
        return element.type === "slot" ? true : isVoid(element);
    };

    return editor;
};

const insertStateSlot = (editor, slot) => {
    Transforms.insertNodes(editor, {
        type: "slot",
        slotId: slot.id,
        children: [{ text: "" }],
    });
    Transforms.move(editor);
};

const Element = (props) => {
    const { attributes, children, element } = props;
    switch (element.type) {
        case "slot":
            return <StateSlotInline {...props} />;
        default:
            return <p {...attributes}>{children}</p>;
    }
};

interface InputInjectableProps {
    value?: string;
    disabled: boolean;
    placeholder?: string;
    onChange: (v?: string) => void;
    options: string[];
    stateSlots?: StateSlot[];
}

export function InputInjectable(props: InputInjectableProps) {
    const { stateSlots = [], onChange, placeholder, value } = props;

    const ref = useRef(null);
    const [target, setTarget] = useState(undefined);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState("");
    const renderElement = useCallback((props) => <Element {...props} />, []);
    const editor = useMemo(
        () => withMentions(withReact(withHistory(createEditor()))),
        []
    );

    const matchedSlots = stateSlots
        .filter((s) => s.name.toLowerCase().startsWith(search.toLowerCase()))
        .slice(0, 10);

    // This stuff is for the autocomplete
    const onKeyDown = useCallback(
        (event) => {
            if (target) {
                switch (event.key) {
                    case "ArrowDown":
                        event.preventDefault();
                        const prevIndex =
                            index >= matchedSlots.length - 1 ? 0 : index + 1;
                        setIndex(prevIndex);
                        break;
                    case "ArrowUp":
                        event.preventDefault();
                        const nextIndex =
                            index <= 0 ? matchedSlots.length - 1 : index - 1;
                        setIndex(nextIndex);
                        break;
                    case "Tab":
                    case "Enter":
                        event.preventDefault();
                        Transforms.select(editor, target);
                        insertStateSlot(editor, matchedSlots[index]);
                        setTarget(null);
                        break;
                    case "Escape":
                        event.preventDefault();
                        setTarget(null);
                        break;
                }
            }
        },
        [index, search, target]
    );

    useEffect(() => {
        if (target && matchedSlots.length > 0) {
            const el = ref.current;
            const domRange = ReactEditor.toDOMRange(editor, target);
            const rect = domRange.getBoundingClientRect();
            el.style.top = `${rect.top + 24}px`;
            el.style.left = `${rect.left}px`;
        }
    }, [matchedSlots.length, editor, index, search, target]);

    return (
        <Slate
            editor={editor}
            value={deserialize(value || "")}
            onChange={(value) => {
                const { selection } = editor;

                // TODO: What does this do?
                if (selection && Range.isCollapsed(selection)) {
                    const [start] = Range.edges(selection);
                    const wordBefore = Editor.before(editor, start, {
                        unit: "word",
                    });
                    const before =
                        wordBefore && Editor.before(editor, wordBefore);
                    const beforeRange =
                        before && Editor.range(editor, before, start);
                    const beforeText =
                        beforeRange && Editor.string(editor, beforeRange);
                    const beforeMatch =
                        beforeText && beforeText.match(/^@(\w+)$/);
                    const after = Editor.after(editor, start);
                    const afterRange = Editor.range(editor, start, after);
                    const afterText = Editor.string(editor, afterRange);
                    const afterMatch = afterText.match(/^(\s|$)/);

                    if (beforeMatch && afterMatch) {
                        setTarget(beforeRange);
                        setSearch(beforeMatch[1]);
                        setIndex(0);
                        return;
                    }
                }

                setTarget(null);

                // Update parent component onChange handler (save to db etc.)
                onChange(serialize(value));
            }}
        >
            <Editable
                renderElement={(props) =>
                    renderElement({ ...props, stateSlots })
                }
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                className="input"
            />
            {target && matchedSlots.length > 0 && (
                // This is the variable chooser menu that appears when you type @
                <div
                    className="Menu"
                    ref={ref}
                    style={{
                        top: "-9999px",
                        left: "-9999px",
                    }}
                >
                    <div className="Menu__section">
                        {matchedSlots.map((slot, i) => (
                            <div
                                className="Menu__section__item"
                                key={`${slot.id}_${i}`}
                                style={{
                                    padding: "0.3rem 0.3rem 0.55rem 0.3rem",
                                    background:
                                        i === index ? "#efefef" : "transparent",
                                }}
                            >
                                <StateSlotComp {...slot} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </Slate>
    );
}

interface Input {
    depends_on?: string[];
    editable: boolean;
    hidden: boolean;
    injectable: boolean;
    key: string;
    name?: string;
    options?: string[];
    placeholder?: string;
    required: boolean;
    state_type?: string;
    type: string;
    value?: string;
}

interface UIInputSingleTextProps {
    input: Input;
    onChange: (v?: string) => void;
    disabled?: boolean;
    autoFocus?: boolean;
    stateSlots: StateSlot[];
}

interface InputDropDownProps {
    value?: string;
    disabled: boolean;
    placeholder?: string;
    onChange: (v?: string) => void;
    options: string[];
}

export function InputDropDown(props: InputDropDownProps) {
    const { value, disabled, placeholder, onChange, options } = props;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div
            className={`UIInput__select ${value && "UIInput__select--filled"} ${
                disabled && "UIInput__select--disabled"
            }`}
            tabIndex={0}
            onClick={() => setIsOpen(true)}
            onBlur={() => setIsOpen(false)}
            onFocus={() => setIsOpen(true)}
        >
            <div className="UIInput__select__value">
                {value || (
                    <div className="UIInput__select__value--placeholder">
                        {placeholder || ""}
                    </div>
                )}
            </div>
            {value && (
                <button
                    className="UIInput__deleter UIInput__select__clear"
                    onClick={(e) => {
                        onChange(undefined);
                        e.stopPropagation();
                    }}
                />
            )}
            {isOpen && (
                <div className="Menu UIInput__select__menu">
                    <div className="Menu__section">
                        {options.map((opt, i) => (
                            <div
                                key={`${opt}_${i}`}
                                className="Menu__section__item"
                                onClick={(e) => {
                                    onChange(opt);
                                    setIsOpen(false);
                                    e.stopPropagation();
                                }}
                            >
                                {opt}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export function UIInputSingleText(props: UIInputSingleTextProps) {
    const { input, onChange, disabled, autoFocus, stateSlots } = props;

    if (input.options && input.options.length) {
        return (
            <InputDropDown
                placeholder={input.placeholder}
                value={input.value}
                options={input.options}
                disabled={disabled || false}
                onChange={onChange}
            />
        );
    } else {
        if (input.injectable) {
            return (
                <InputInjectable
                    stateSlots={stateSlots}
                    onChange={onChange}
                    value={input.value}
                    placeholder={input.placeholder}
                    disabled={disabled}
                />
            );
        }
        return (
            <input
                className="input"
                id={input.id}
                value={input.value}
                onChange={(e) => onChange(e.target.value)}
                autoFocus={autoFocus}
                disabled={disabled}
                placeholder={input.placeholder || ""}
            />
        );
    }
}
